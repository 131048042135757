.attachments {
  width: 100%;
  height: 800px;
  background: rgb(248, 248, 248);
  background: linear-gradient(180deg, rgb(250, 250, 250) 60%, rgb(231, 231, 231) 100%);
  
  .title {
    width: 100%;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.22em;
    text-align: center;
    color: #3d0404;
    padding: 80px 0 30px 0;
  }
  
  .button-list {
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;

    button {
      all: unset;
      cursor: pointer;
      padding: 4px 16px;
      margin: 4px;
      border: 1px solid #ad0000;
      border-radius: 25px;
      font-size: 14px;
      font-weight: 400;
    }

    .button-active {
      background: #b90c0c;
      color: white;
      box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
      transition: all 100ms linear;
    }
  }

  .img-wrapper {
    width: 100%;
    max-width: 800px;
    height: 620px;
    margin: 0 auto 0;
    z-index: 0;
    position: relative;

    .img {
      width: 2500px;
      height: 1500px;
      position: absolute;
      transform: scale(0.7);
      transition: 0.6s ease-out;
    }

    .img-prop {
      position: absolute;
      width: 2500px;
      height: 1500px;
      opacity: 0;
    }

    .transition {
      transition: 0.5s;
    }

    .data-collection {
      background: url("../static/attachment-img/grover.png") 0 0 / contain no-repeat;
    }

    .mowing {
      background: url("../static/attachment-img/mowing.png") 0 0 / contain no-repeat;
    }

    .precision-spraying {
      background: url("../static/attachment-img/spraying.png") 0 0 / contain no-repeat;
    }

    .golf-ball-collection {
      background: url("../static/attachment-img/golf-collection.png") 0 0 / contain no-repeat;
    }

    .towing {
      background: url("../static/attachment-img/towing.png") 0 0 / contain no-repeat;
    }

    .contact-modal {
      visibility: hidden;
      width: 100%;
      max-width: 800px;
      height: auto;
      padding: 60px 0;
      margin: 0 auto;
      border-radius: 4px;
      background-color: rgb(247, 247, 247);
      box-shadow: 2px 5px 6px 0 rgba(0, 0, 0, 0.48);
      opacity: 0;
      transition: all 500ms ease;
      position: relative;
      z-index: 999;

      .desc {
        width: 500px;
        margin: 0 auto;

        h1 {
          color: #3d0404;
          margin: 0;
          padding-bottom: 16px;
        }

        p {
          width: 90%;
          margin: 0 auto;
        }
      }

      .contact-form-wrapper {
        width: 500px;
        padding-top: 50px;
        margin: auto;
      }
    }

    .fade-in {
      opacity: 1;
      transition: opacity 0.7s ease-out;
      z-index: 1;
    }

    .fade-out {
      opacity: 0;
      transition: opacity 0.7s ease-out;
      z-index: 99;
    }

    .unhide {
      visibility: visible;
      opacity: 1;
    }
  }
}

.attachments-contact-form {
  min-height: 850px;

  .img-wrapper-contact-form {
    min-width: 100%;

    .contact-modal-s {
      position: relative;
      height: 800px;
      width: 100%;
      padding: 30px 0;
      z-index: 999;
      box-shadow: none;

      .desc {
        width: 100%;
        margin: 0 auto;

        h1 {
          font-size: 26px;
        }

        p {
          font-size: 12px;
        }
      }

      .contact-form {
        width: 80%;
        padding-top: 30px;
        margin: auto;

        .contact-form-wrapper {
          width: 100%;

          .input-wrapper {
            width: 100%;
          }

          .contact-btn {
            margin: 0 auto;
          }
        }
      }
    }
  }
}

.attachments-m {
  height: 750px;
}
.attachments-s {
  height: 700px;
}

.img-wrapper-m {
  max-width: 500px !important;
}
.img-wrapper-s {
  max-width: 370px !important;
}

.attachments-contact-form-m {
  min-height: 800px !important;
}

.attachments-contact-form-s {
  min-height: 900px !important;
}
