@mixin full-size {
  width: 100%;
  height: 100%;
}

@mixin pseudo {
  display: inline-block;
  content: "";
  position: absolute;
  width: 140px;
}

$dashedLineCurve: cubic-bezier(0.65, 0, 0.26, 1);

.customisable-item {
  height: 640px;
  padding: 0 2% 0 2%;
  position: relative;

  .content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .desc {
      width: 40%;
      text-align: left;
      font-size: 16px;
      font-weight: 500;
      padding: 30px 10px 0 40px;
      background-color: #ffffffbb;
      z-index: 100;

      .title {
        letter-spacing: -1px;

        p {
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 3px;
          text-transform: uppercase;
          color: #8f0000;
        }
      }

      p {
        margin: 30px auto;
        line-height: 20px;
      }
    }

    .customisable-img-wrapper {
      max-width: 550px;
      width: 55%;
      height: 500px;
      position: absolute;
      left: 5%;
      display: flex;
      align-items: flex-end;

      .customisable-img {
        max-width: 600px;
        width: 100%;
        height: 470px;
        z-index: -1;
        position: absolute;
        right: 0;
        top: -40px;
        background: url("../static/custom.png") center / contain no-repeat;
        animation: fade-in 0.6s $dashedLineCurve both;

        .before-img {
          position: absolute;
          width: 160px;
          height: 160px;
          border: 2px dashed #e3000f;
          left: 52%;
          top: 90px;
          visibility: hidden;
        }

        .after-img {
          position: absolute;
          width: 150px;
          height: 130px;
          border: 2px dashed #e3000f;
          left: 2%;
          top: 230px;
          visibility: hidden;
        }
      }

      .icon-list {
        position: relative;
        display: flex;
        justify-content: space-around;
        width: 100%;
        height: auto;
        font-size: 14px;
        font-weight: 600;

        .icon-item {
          display: flex;
          flex-direction: column;
          visibility: hidden;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          width: 100%;
          margin: 0 auto;
        }

        .name {
          font-size: 14px;
          font-weight: 500;
          margin: 10px auto;
        }
      }
    }
  }
}

.customisable-item-s {
  width: 100%;
  height: auto;
  margin: 20px auto;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .desc {
    margin: 0 auto;
    padding: unset !important;
    width: 80vw !important;
    font-weight: 500;
    backdrop-filter: blur(2px);
    text-align: center !important;

    .title {
      p {
        text-align: center !important;
        margin: 20px auto;
      }
    }
  }

  .customisable-img-wrapper {
    width: 100%!important;
    z-index: -1;
    position: relative !important;
    left: unset !important;
    display: flex;
    
    .customisable-img {
      height: 80vw;
      width: 98vw;
      overflow: visible;
      background-position: 50%;
      z-index: -1;
      position: relative;

      .before-img {
        position: absolute;
        width: 36vw;
        height: 32vw;
        box-sizing: border-box;
        border: 2px dashed #e3000f;
        left: 50vw;
        top: 10vw;
        visibility: hidden;
        overflow: visible;
      }

      .after-img {
        position: absolute;
        width: 30vw;
        height: 28vw;
        box-sizing: border-box;
        border: 2px dashed #e3000f;
        left: 3vw;
        top: 32vw;
        visibility: hidden;
        overflow: visible;
      }
    }

    .icon-list {
      position: relative !important;
      display: flex;
      justify-content: space-between;
      width: 100% !important;
      margin: 0 auto;
      font-size: 14px;
      font-weight: 600;
      visibility: hidden;

      .icon-item {
        display: flex;
        flex-direction: column;
        width: 60px;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 50px;
          margin: 0 auto;
        }

        .name {
          margin-top: 14px;
        }
      }
    }
  }
}

.frame-box-animate-before {
  animation: scale-up-center 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.2s both;
  visibility: visible !important;
}

.frame-box-animate-after {
  animation: scale-up-center 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) both;
  visibility: visible !important;
}

.icon-item-animate-0 {
  animation: slide-up 1s $dashedLineCurve both;
  visibility: visible !important;
}

.icon-item-animate-1 {
  animation: slide-up 1s $dashedLineCurve 0.2s both;
  visibility: visible !important;
}

.icon-item-animate-2 {
  animation: slide-up 1s $dashedLineCurve 0.4s both;
  visibility: visible !important;
}

.icon-item-animate-3 {
  animation: slide-up 1s $dashedLineCurve 0.6s both;
  visibility: visible !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
