.applications-item {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 60px;

  .applications-landing {
    width: 100%;
    background-color: #191919;
    padding: 60px 2%;

    .title {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: white;
      text-align: left;
    }

    .application-desc {
      max-width: 700px;
      width: 60%;
      text-align: left;
      font-weight: 500;
      margin-top: 10px;
      color: white;
    }
  }

  .icons-img {
    margin: 30px auto;
  }
}

.applications-item-s {
  .title {
    text-align: center !important;
  }

  .application-desc-s {
    width: 80vw;
    margin: 20px auto 50px;
    font-weight: 500;
    color: white;
  }

  .applications-landing {
    height: auto;
  }
}
