html {
    scrollbar-width: none;
    height: 100%;
}

body::-webkit-scrollbar {
    display: none;
}

body {
    -ms-overflow-style: none;
    height: 100%;
    overflow: auto;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin: 0;
}

input, textarea {
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
}

textarea {
    resize: none;
    overflow: hidden;
}