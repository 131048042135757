.contact {
  display: flex;
  height: auto;

  .contact-content {
    flex: 1;
    border-right: 1px solid #a3000b;
    padding: 60px 5% 0 5%;

    .title {
      color: #b90c0c;
      font-size: 36px;
      font-weight: 500;
      letter-spacing: -1px;

      p {
        text-align: left;
      }
    }

    .desc {
      font-weight: 500;
      margin-top: 20px;
      padding-bottom: 40px;
      text-align: left;
    }
  }
}

.contact-s {
  flex-direction: column;
  height: auto;
  margin-bottom: 60px;

  .contact-content {
    border: none;
    margin: 0 auto;
    padding: 60px 20px 20px 30px;

    .title {
      font-size: 32px;

      p {
        text-align: center;
      }
    }

    .desc {
      text-align: center;
    }
  }

  .contact-form-wrapper {
    margin: 20px;
    padding: 0;

    .input-wrapper {
      width: 100%;
    }

    .contact-btn-wrapper {
      .contact-btn {
        margin: 0 auto;
      }
    }
  }
}
