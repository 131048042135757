.header {
  width: 100%;
  height: 160px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgb(34, 34, 34);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 50%, rgba(53, 53, 53, 0) 100%);

  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
  }

  .logo {
    width: 160px;
    height: 150px;
    padding-left: 2%;
  }
}

.header-s {
  height: 140px;

  .logo {
    width: 120px;
    height: 100px;
  }
}
