.footer {
  height: 260px;
  padding-top: 20px;
  background-color: #242424;
  color: #ffffff;

  .footer-content {
    display: flex;

    .footer-logo {
      flex: 1;
      padding-top: 60px;
      text-align: left;

      .footer-logo-img {
        width: 150px;
      }

      p {
        margin-left: 8px;
        font-size: 8px;
      }
    }

    .footer-info {
      flex: 1;
      display: flex;
      justify-content: space-around;
      padding-top: 40px;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      
      .footer-address {
        width: 190px;
        margin-right: 2%;

        p {
          margin-bottom: 4px;
          text-decoration: underline;
        }

        .footer-address-item {
          display: block;
        }
      }

      .footer-phone-follow {
        width: 190px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
          margin-bottom: 4px;
          text-decoration: underline;
        }

        .footer-follow-img {
          cursor: pointer;
          margin-top: 2px;
        }
      }
    }
  }
}

.footer-s {
  height: auto;
  padding-bottom: 40px;
  
  .footer-content {
    flex-direction: column-reverse;
    justify-items: center;
    
    .footer-logo {
      padding: 0;
      margin: 40px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .footer-logo-img {
        width: 120px;
        margin: 0 auto;
      }

      p {
        text-align: center;
      }
    }
  }

  .footer-info {
    flex-direction: initial;

    .footer-address,
    .footer-phone-follow {
      max-width: 34%;
    }
  }
}
