.landing-feature-icon-list {
  width: 100%;
  background: rgb(34, 34, 34);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 50%, rgba(53, 53, 53, 0) 100%);
  backdrop-filter: blur(2px);
  position: absolute;
  bottom: 0;

  .landing-feature-icon-list-wrapper {
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 5vh;

    .feature-icon {
      flex: 1;
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 48px;
      border-right: 2px solid #df0011;

      span {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        align-self: center;
        padding-bottom: 2px;
      }

      span:nth-child(1) {
        width: 48px;
      }
    }

    .feature-icon:nth-last-child(1) {
      border-right: none;
    }
  }
}

.landing-feature-icon-list-wrapper-s {
  width: 100%;
  max-width: 500px !important;
  height: 200px;
  margin: 0 auto;

  .feature-icon {
    border: none !important;
    width: 100% !important;
    flex: 50% !important;
    justify-content: flex-start;
    margin: 0 auto;
  }
}
