.call-outs-wrapper {
  width: 560px;
  height: 560px;
  position: relative;

  .call-out {
    position: absolute;
    width: 300px;
    height: 300px;
    overflow: visible;

    .call-out-origin {
      background-color: rgba(255, 173, 49, 0.9);
      position: relative;
      border-radius: 96px;
      width: 15px;
      height: 15px;
      margin: 0 auto;
      transition: 0.3s;
      box-shadow: 1px 1px 5px 2px #2727276c;
      cursor: pointer;
      z-index: 2;
    }
    
    .call-out-origin::after {
      content:"";
      background-color: rgba(255, 255, 255, 0);
      border: 2px solid #ffffff;
      position: absolute;
      border-radius: 96px;
      top: -5px;
      left: -5px;
      width: 21px;
      height: 21px;
      transition: 0.3s;
      box-shadow: 1px 1px 5px 2px #2727276c;
      cursor: pointer;
      z-index: 2;
    }

    .call-out-origin-active {
      transform: scale(1.4);
      z-index: 1;
    }

    .call-out-desc {
      opacity: 0;
      position: relative;
      height: auto;
      width: auto;
      max-width: 100%;
      background-color: #121212e0;
      box-shadow: 3px 3px 8px #00000080;
      border-radius: 4px;
      color: #fff;
      padding: 14px 24px;
      box-sizing: border-box;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      transition: opacity 0.6s;
      z-index: 1;
    }

    .show {
      opacity: 1;
    }

    .gps-desc {
      top: -30px;
      left: -116px;
    }

    .front-camera-desc{
      top: -30px;
      left: 80px;
    }

    .battery-pack-desc{
      top: -30px;
      left: -60px;
    }

    .front-left-wheel-desc{
      top: 160px;
      left: 50px;
    }

    .lift-arm-desc{
      top: 160px;
      left: 160px;
    }

    .front-right-wheel-desc{
      top: 140px;
      left: -100px;
    }

    .call-out-mid-pt {
      // height: 1px;
      // width: 1px;
      // border: 1px red solid;
      left: calc(50% - 1px);
      top: 110px;
      position: absolute;
    }

    .gps-mid-pt,
    .front-right-wheel-mid-pt {
      left: calc(50% - 40px);
    }
    
    .battery-pack-mid-pt {
      top: 133px;
      left: calc(50% - 30px);
    }

    .lift-arm-mid-pt,
    .front-camera-mid-pt{
      left: calc(50% + 36px);
    }

    .front-left-wheel-mid-pt {
      left: calc(50% + 28px);
    }

  }
}

.call-outs-wrapper-s {
  width: 380px;
  height: 380px;

  .call-out {
    .gps-desc {
      top: -70px;
      left: -26px;
    }
    .call-out-origin {
      width: 15px;
      height: 15px;
    }

    .front-camera-desc {
      top: -90px;
      left: -102px;
    }

    .battery-pack-desc {
      top: -170px;
      left: 94px;
    }

    .front-left-wheel-desc {
      top: -190px;
      left: -146px;
    }

    .lift-arm-desc {
      top: -230px;
      left: -56px;
    }

    .front-right-wheel-desc {
      top: -280px;
      left: 4px;
    }

    .call-out-mid-pt {
      left: calc(50% - 1px);
      top: 80px;
      position: absolute;
    }
  }
}

.shadow-drop-center {
  animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes shadow-drop-center {
  0% {
    transform: translateZ(0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    transform: translateZ(50px);
    box-shadow: 0 0 20px 10px #e11010;
  }
}
