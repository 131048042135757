@import "wrapper.scss";
@import "header.scss";
@import "landing.scss";
@import "attachments.scss";
@import "landingFeatureIconList";
@import "dividerBar";
@import "featuresCallOut";
@import "features";
@import "navMode";
@import "customisable";
@import "applicationsItem";
@import "iconsImgItem";
@import "contact";
@import "footer";
@import "contactForm";

html {
  scrollbar-width: none;
  height: 100%;
}

body {
  height: 100%;
}

body::-webkit-scrollbar {
  display: none;
}

.App {
  text-align: center;
  width: 100vw;
  overflow: hidden;
}

.opacity {
  opacity: 0;
}

.orchard {
  background: url("../static/application-img1.jpg") 0 / cover no-repeat;
}

.golfcourse {
  background: url("../static/application-img2.jpg") 0 / cover no-repeat;
}

.viticulture {
  background: url("../static/application-img3.jpg") 0 / cover no-repeat;
}

.custom-car-bottom {
  background: url("../static/custom.png") 0 / 100% no-repeat;
}

.landing-pic {
  background: url("../static/landing-pic.jpg") 0 / cover no-repeat;
}

.landing-pic-s {
  background: url("../static/landing-pic.jpg") 10% 0 / cover no-repeat;
}
