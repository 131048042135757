.icons-img {
  height: 250px;
  display: flex;
  justify-content: space-between;
  margin: 10px auto;

  .icons-list {
    width: 49%;

    .title {
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 50px 0 0 50px;
      text-align: left;
    }

    .icons-group {
      display: flex;
      margin: 30px auto 70px;
      justify-content: space-around;

      .icon-item {
        display: flex;
        flex-direction: column;
        width: 80px;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          width: 60px;
          margin: 0 auto;
        }

        .text {
          font-size: 14px;
          font-weight: 500;
          margin: 10px auto;
        }
      }
    }
  }

  .img {
    border-radius: 6px;
    height: 100%;
    width: 49%;
  }
}

.icons-img-s {
  height: 500px;
  margin: 20px 12px 0;

  .icons-list {
    width: 100%;
    margin-right: 0;

    .title {
      width: 90%;
      font-size: 20px;
      font-weight: 600;
      margin: 40px auto 0;
      text-align: left;
    }

    .icons-group {
      margin: 30px auto;
    }
  }

  .img {
    width: 100%;
  }
}

.reverse {
  flex-direction: row-reverse;
}

.column-reverse {
  flex-direction: column-reverse;
}
