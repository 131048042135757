.divider-bar {
  color: #ffffff;
  font-size: 24px;
  position: relative;
}

.red-gradient {
  background: linear-gradient(to right, #e3000f, #720008 100%);
}

.red-gradient-reverse {
  background: linear-gradient(to left, #e3000f, #720008 100%);
}

.grey {
  background: linear-gradient(to right, #e6e6e6, #e6e6e6 100%);
}

.black {
  background: rgb(31, 31, 31);
}
