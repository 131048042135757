.features-section {
  width: 100%;
  height: 660px;
  aspect-ratio: 5/2.8;
  padding-top: 80px;
  background-color: white;

  .title {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;
    text-transform: uppercase;
    color: #8f0000;
  }

  .features-body {
    width: 100%;
    height: auto;
    display: flex;
    width: 560px;
    height: 560px;
    margin: 50px auto 0;
    position: relative;

    .features-video-wrapper {
      position: absolute;
    }

    .origin-gps {
      top: 30px;
      left: 150px;
    }

    .origin-front-camera {
      top: 48px;
      left: 250px;
    }

    .origin-front-left-wheel {
      top: 170px;
      left: 310px;
    }

    .origin-battery-pack {
      top: 150px;
      left: 0px;
    }

    .origin-lift-arm {
      top: 230px;
      left: 180px;
    }

    .origin-front-right-wheel {
      top: 300px;
      left: 100px;
    }
  }
}

.features-section-s {
  max-height: 580px;
  padding-top: 60px;

  .features-body {
    margin: 130px auto;
    height: 100%;
    position: relative;
    min-width: 300px;
    width: 370px;
    height: 370px;

    .features-video-wrapper {
      position: absolute;
      left: -20px
    }

    .origin-gps {
      top: -10px;
      left: 60px;
    }

    .origin-front-camera {
      top: 10px;
      left: 136px;
    }

    .origin-front-left-wheel {
      top: 110px;
      left: 180px;
    }

    .origin-battery-pack {
      top: 90px;
      left: -60px;
    }

    .origin-lift-arm {
      top: 150px;
      left: 90px;
    }

    .origin-front-right-wheel {
      top: 200px;
      left: 30px;
    }
  }
}
