@mixin full-size {
    width: 100%;
    height: 100%;
}

@mixin pseudo {
    display: inline-block;
    content: "";
    position: absolute;
    width: 140px;
}

$dashedLineCurve: cubic-bezier(0.65, 0, 0.26, 1);

.nav-modes {
    height: 640px;
    position: relative;

    .content {
        display: flex;
        justify-content: space-between;

        .desc {
            width: 40%;
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            padding: 30px 40px 0 20px;
            background-color: #ffffff80;
            z-index: 100;

            .title {
                letter-spacing: -1px;

                p {
                    font-size: 24px;
                    font-weight: 700;
                    letter-spacing: 3px;
                    text-transform: uppercase;
                    color: #8f0000;
                }
            }

            p {
                margin: 30px auto;
                line-height: 20px;
            }

            .nav-mode-list {
                p {
                    margin: 10px auto;
                }
                p::before {
                    content: "∷ ";
                    color: #e3000f;
                }
            }
        }

        .nav-img-wrapper {
            width: 60%;
            position: absolute;
            right: 0;
            top: 30px;

            .nav-img {
                width: 100%;
                height: 460px;
                position: absolute;
                top: 60px;
                right: 5%;
                background: url("../static/ui.png") 100% / contain no-repeat;
                animation: fade-in 0.6s $dashedLineCurve both;
            }
        }
    }
}

.nav-modes-s {
    width: 100%;
    height: auto;
    margin: 20px auto;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .content {
        display: flex;
        flex-direction: column;
        position: relative;

        .desc {
            margin: 0 auto;
            padding: unset !important;
            width: 80vw !important;
            font-weight: 500;
            backdrop-filter: blur(2px);
            text-align: center !important;

            .title {
                p {
                    text-align: center !important;
                    margin: 20px auto;
                }
            }
        }

        .nav-img-wrapper {
            margin-top: 40px;
            width: 100%;
            top: 0;
            position: relative;
            display: flex;

            .nav-img {
                background: url("../static/ui-s.png") center / contain no-repeat;
                position: relative;
                height: 100%;
                min-height: 300px;
                top: unset;
                right: unset;
            }
        }
    }
}

.frame-box-animate-before {
    animation: scale-up-center 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.2s both;
    visibility: visible !important;
}

.frame-box-animate-after {
    animation: scale-up-center 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) both;
    visibility: visible !important;
}

.icon-item-animate-0 {
    animation: slide-up 1s $dashedLineCurve both;
    visibility: visible !important;
}

.icon-item-animate-1 {
    animation: slide-up 1s $dashedLineCurve 0.2s both;
    visibility: visible !important;
}

.icon-item-animate-2 {
    animation: slide-up 1s $dashedLineCurve 0.4s both;
    visibility: visible !important;
}

.icon-item-animate-3 {
    animation: slide-up 1s $dashedLineCurve 0.6s both;
    visibility: visible !important;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes scale-up-center {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}
