.landing {
  width: 100%;
  height: 100vh;
  max-height: 1200px;
  position: relative;

  .landing-pic {
    height: 100%;
    width: 100%;
    position: absolute;
    filter: brightness(80%);
  }

  .content-wrapper {
    position: relative;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;

    .content {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .landing-msg {
        padding-bottom: 10%;
      }

      .desc {
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        p {
          width: 100%;
          max-width: 800px;
          margin: 20px auto;
          font-weight: 600;
          font-size: 42px;
          text-align: center;
          color: white;
          text-shadow: 2px 2px 10px black;
        }

        .play-video-btn-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .play-video-btn {
          width: 140px;
          height: 40px;
          border-radius: 50px;
          padding: 0 12px;
          background: rgba(0, 0, 0, 0.3);
          border: 1px solid rgb(232, 0, 0);
          color: rgb(255, 255, 255);
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;
          font-size: 12px;
          box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.527);
          display: flex;
          justify-content: space-around;
          align-items: center;
          cursor: pointer;
        }
      }

      .modal-video {
        background-color: rgba(0, 0, 0, 0.99);

        .modal-video-close-btn {
          cursor: pointer;
        }
      }
    }
  }
}

.landing-s {
  height: 860px;
  background-color: black;

  .landing-pic-s {
    max-height: 700px;
  }

  .content-s {
    .landing-msg {
      padding-bottom: 50% !important;
    }

    .desc {
      width: 80%;
      height: 100%;

      .play-video-btn {
        background: rgba(0, 0, 0, 0.523) !important;
      }

      p {
        font-size: 36px;
      }
    }
  }
}
