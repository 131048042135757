.contact-form-wrapper {
  flex: 1;
  padding: 60px 5% 40px 5%;
  min-width: 200px;

  .input-wrapper {
    border-bottom: 1px solid #acacac;
    text-align: left;
    height: 30px;
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;
    position: relative;

    label {
      font-weight: 500;
      width: 80px;
      text-align: left;
      height: 100%;
      align-items: flex-end;
      display: flex;
    }

    input {
      width: 420px;
      height: 100%;
      font-size: 16px;
      padding: 10px 4px 0 0;
      box-sizing: border-box;
      vertical-align: bottom;
      background: none;
    }

    .contact-error-msg {
      color: #e3000f;
      position: absolute;
      left: 100px;
      top: 32px;
      font-size: 12px;
    }
  }

  .contact-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    height: 32px;

    .contact-btn {
      appearance: none;
      width: 192px;
      height: 32px;
      padding: 8px 66px;
      color: #e3000f;
      background: none;
      border: 1px solid #e3000f;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 600;
    }
  }
}

.ct-toast {
  color: rgb(71, 71, 71);
  border-radius: 8px;
  z-index: 20000;
}

.ct-toast-loading {
  border-left: 0 !important;
  border-top: 0 solid rgb(150, 150, 150);
}

.ct-toast-success {
  border-left: 0 !important;
  border-top: 0 solid rgb(81, 173, 243);
}

.ct-toast path {
  fill: rgb(45, 115, 168) !important;
}

.ct-icon-loading:after {
  border-width: 2px;
  border-style: solid;
  border-color: rgb(150, 150, 150) transparent rgb(150, 150, 150) transparent;
}
